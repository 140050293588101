import Modal from "react-bootstrap/Modal";
import { ReactSVG } from "react-svg";
import { NavDropdown, Table } from "react-bootstrap";
import srxlogowithtext from "../../../../../assets/images/SRX-logo-with-text.svg";
import sflogo from "../../../../../assets/images/SourceFuse-logo.png";
import downloadicon from "../../../../../assets/images/download-icon.svg";
import exporticon from "../../../../../assets/images/export-icon.svg";
import excelicon from "../../../../../assets/images/excel-icon.svg";
import pdficon from "../../../../../assets/images/pdf-icon.svg";
import arrowdown from "../../../../../assets/images/angle-down.svg";
import useInventory from "../../../../../Hooks/useInventory";
import { useEffect, useState } from "react";
import moment from "moment";
import Loading from "../../../../../components/LoadingPage/Loading";
export const InventoryTransactionReportsModel = ({
  showReportModal,
  onCloseReportModal,
  selectedItem,
}: any) => {
  const [TrReportData, setTrReportData] = useState<any>([]);
  const { inventoryTransactionsAPI, inventoryTransactionsLoading } =
    useInventory();
  useEffect(() => {
    inventoryTransactionsAPI(selectedItem.rxSRXId).then((res) => {
      setTrReportData(res[0]);
    });
  }, []);
  const getTotal = () => {
    let sum = 0;
    TrReportData.reportData?.forEach((x: any) => {
      sum = sum + parseFloat(x.quantity);
    });
    return parseFloat(sum.toString()).toFixed(2);
  };
  return (
    <Modal
      size="xl"
      id="trans-report-modal"
      show={showReportModal}
      onHide={onCloseReportModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>View Report</h4>
          <div className="trans-report-action-wrapper">
            <div className="trans-report-action-box download-report-box">
              <ReactSVG className="svg-box" wrapper="span" src={downloadicon} />
            </div>
            <div className="trans-report-action-box export-report-opt-box">
              <ReactSVG className="svg-box" wrapper="span" src={exporticon} />
              <NavDropdown title="Export Report">
                <NavDropdown.Item>
                  <img src={excelicon} alt="" /> Excel
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <img src={pdficon} alt="" /> PDF
                </NavDropdown.Item>
              </NavDropdown>
              <ReactSVG className="svg-box" wrapper="span" src={arrowdown} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {inventoryTransactionsLoading && <Loading />}

        <div className="trans-report-content-wrapper">
          <div className="trans-report-content-header">
            <div className="header-content">
              <h5>Transaction Report</h5>
              <p>{TrReportData.subTitleReport}</p>
            </div>
            <div className="header-logo-wrapper">
              <div className="header-logo-box srx-logo-box">
                <img src={srxlogowithtext} alt="srx" />
              </div>
              <div className="header-logo-box sf-logo-box">
                <img src={TrReportData.clientImage} alt="img" />
              </div>
            </div>
          </div>
          <div className="trans-report-detail-box-wrapper">
            <h5>{TrReportData.rxName}</h5>
            <div
              className="trans-report-detail-box-container"
              style={{ overflow: "auto" }}
            >
              <div className="trans-report-detail-box">
                <label>SRX Barcode</label>
                <p>{TrReportData.rxSRXId}</p>
              </div>
              <div className="trans-report-detail-box">
                <label>Manufacturer</label>
                <p>{TrReportData.manufacturer}</p>
              </div>
              <div className="trans-report-detail-box">
                <label>NDC</label>
                <p>{TrReportData.ndcCode}</p>
              </div>
              <div className="trans-report-detail-box">
                <label>LOT Number</label>
                <p>{TrReportData.lotNo}</p>
              </div>
              <div className="trans-report-detail-box">
                <label>Expiration Date</label>
                <p>
                  {TrReportData.expiryDate
                    ? moment(TrReportData.expiryDate).format("MM/DD/YYYY")
                    : "NA"}
                </p>
              </div>
            </div>
            <div
              className="trans-report-table-wrapper"
              style={{ overflow: "auto" }}
            >
              <Table bordered={false} hover={true}>
                <thead>
                  <tr>
                    <th>DATE</th>
                    <th>TRANS.Type</th>
                    <th>Ref. No</th>
                    <th>user</th>
                    <th>location</th>
                    <th>Qty.</th>
                    <th>Current qty.</th>
                    <th>price</th>
                    <th>trans.value</th>
                    <th>current value</th>
                  </tr>
                </thead>
                <tbody>
                  {TrReportData.reportData?.map((report: any) => (
                    <tr>
                      <td>
                        {report.createdOnUtc
                          ? report.createdOnUtc
                          : "NA"
                          }
                      </td>
                      <td>{report.transactionName}</td>
                      <td>{report.referenceNumber}</td>
                      <td>{report.userName}</td>
                      <td>{report.locationName}</td>
                      <td>{report.quantity}</td>
                      <td>{report.qty1}</td>
                      <td>$40</td>
                      <td>$200</td>
                      <td>$200</td>
                    </tr>
                  ))}

                  <tr>
                    <th colSpan={5}>Total</th>
                    <th colSpan={5}>{getTotal()}</th>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
